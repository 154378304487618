import React from 'react'

export default function Downloader() {
  return (
    <div className="container d-flex align-items-center justify-content-center min-vh-100">
            <div className="text-center">
                <h1 className="display-4 text-danger">Error</h1>
                <p className="lead text-muted">
                    The external plugin you are trying to use is not allowed. Please check your settings or contact support for assistance.
                </p>
                <a href="/" className="btn btn-primary">Return to Homepage</a>
            </div>
        </div>
  )
}
